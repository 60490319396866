import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useParams, useLocation } from 'react-router-dom';
import GridLoader from 'react-spinners/GridLoader'
import { apiPath, imagePath } from '../constants.js'


export default function MarketChart() {
    var { cardId } = useParams();
    var [pricingData, updatePricingData] = useState([])
    var [volumeData, updateVolumeData] = useState([])
    var [currentPriceData, updateCurrentPriceData] = useState([])
    var [cardData, updateCardData] = useState([])
    var location = useLocation();

    useEffect(() => {
        updateCardData([]);
        fetch(`${apiPath}/api/card/${cardId}/price/history`).then(response => response.json()).then((output) => {
            updateCardData({
                cardName: output["cardName"],
                konamiId: output["konamiId"],
                passcode: output["passcode"],
            })
            updatePricingData(output["prices"])
            updateVolumeData(output["volume"])
            updateCurrentPriceData(output["currentPrices"])
            //ApexCharts.getChartByID('card-lines').updateSeries(pricingData);
        })
    }, [cardId, location]);

    //var testData = fetch(`http://${apiPath}/api/GetFullCardPricingData?cardName=Raigeki`);

    document.body.parentElement.style = "background: #f8f9fa; background-size: cover;";
    document.body.style = "background: none; height: 100%;";

    var priceChartOptions = {
        chart: {
            height: 650,
            zoomType: 'x'
        },
        title: {
            text: `Card Price History - ${cardData.cardName}`,
            align: 'left'
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                // don't display the year
                month: '%e. %b',
                year: '%b'
            },
            title: {
                text: 'Date'
            }
        },
        yAxis: [{
            title: {
                text: 'Price ($)'
            },
            height: '70%',
            lineWidth: 2,
            resize: {
                enabled: true
            }
        },
            {
                title: {
                    text: 'Volume'
                },
                top: '75%',
                height: '25%',
                offset: 0,
                lineWidth: 2
            }
        ],
        tooltip: {
            crosshairs: true
        },
        legend: {
            maxHeight: 200,
            itemWidth: undefined,
            itemDistance: 10
        },
        plotOptions: {
            series: {
                marker: {
                    symbol: 'circle',
                    fillColor: '#FFFFFF',
                    enabled: true,
                    radius: 0,
                    lineWidth: 1,
                    lineColor: null
                }
            },
            column: {
                maxPointWidth: 20,
                stacking: 'normal'
            }
        },
        series: [...pricingData, ...volumeData],

        credits: {
            enabled: false
        }
    }

    if (cardData.cardName === undefined) {
        return (
            <div style={{height: "100%"}} >
                <div style={{ width: "320px", height: "310px", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, margin: "auto" }}>
                    <GridLoader
                        color={'rgb(25, 135, 84)'}
                        size={100}
                        margin={5}
                        aria-label="Loading Grid"
                        data-testid="loader"
                    />
                    </div>
            </div>
        )
    }
    else {
        return (
            <div className="app">
                <div id="wrapper">
                    <div class="content-area">
                        <div class="container-fluid">
                            <div class="main">
                                <div class="row mt-4">
                                    <div class="col-md-5">
                                        <div class=" mt-4">
                                            <div id="radialBarBottom">
                                                <h1>{cardData.cardName}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-md-4">
                                        <div class="row mt-4">
                                            <div class="box rounded shadow" style={{ backgroundColor: "white", height: "650px", display: "flex", alignItems: "center" }} >
                                                <img src={`${imagePath}/card/${cardData.passcode}.jpg`} style={{ maxWidth: "100%", maxHeight: "600px", margin: "auto" }} alt={`Yugioh Card ${cardData.cardName}`} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8 rounded">
                                        <div class="box shadow mt-4 rounded">
                                            <div id="chart-line rounded">
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={priceChartOptions}
                                                />
                                                {/*<Chart options={options} series={pricingData} type="line" />*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div class="row sparkboxes mt-4">*/}
                    {/*    <div class="col-md-3">*/}
                    {/*        <div class="box box1">*/}
                    {/*            <div class="details">*/}
                    {/*                <h3>1213</h3>*/}
                    {/*                <h4>BOOKMARKED</h4>*/}
                    {/*            </div>*/}
                    {/*            <div id="spark1"></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div class="col-md-3">*/}
                    {/*        <div class="box box2">*/}
                    {/*            <div class="details">*/}
                    {/*                <h3>422</h3>*/}
                    {/*                <h4>VIEWS 24H</h4>*/}
                    {/*            </div>*/}
                    {/*            <div id="spark2"></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div class="col-md-3">*/}
                    {/*        <div class="box box3">*/}
                    {/*            <div class="details">*/}
                    {/*                <h3>92031</h3>*/}
                    {/*                <h4>VIEWS ALL TIME</h4>*/}
                    {/*            </div>*/}
                    {/*            <div id="spark3"></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div class="sparkboxes mt-5" style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "space-around" }} >
                        {currentPriceData.map(function (card) {
                            return <div class="col-md-3">
                                <div class="box shadow p-2 h-100 box1 bg-white rounded" style={{flexBasis: "100%"}} >
                                    <div class="details" style={{ textAlign: "center" }} >
                                        <h3>{card.name}</h3>
                                        <h3>${card.price}</h3>
                                        <br />
                                        <h4><a href={`https://tcgplayer.com/product/${card.id}`} target="_blank" rel="noopener noreferrer">BUY ON TCGPLAYER</a></h4>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>


            </div>
        );
    }


    

}