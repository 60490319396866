import React, { useState, useEffect } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { Layout } from './components/Layout';
import MarketChart from './components/MarketChart';
import Landing from './components/Landing'
import './custom.css';
import { apiPath } from './constants';
import { CardContext } from './CardContext';



const App = () => {

    const [allCardData, setAllCardData] = useState([])

    useEffect(() => {
        fetch(`${apiPath}/api/cards`).then(response => response.json()).then((output) => {
            setAllCardData(output)
        })
    }, []);

    return (
        <CardContext.Provider value={{ allCardData }} >

        <Routes>
            <Route path="/" element={<Landing />} />
            <Route element={<Layout><Outlet /></Layout>} >
                <Route path="/MarketChart/:cardId" element={<MarketChart />} />
            </Route>
            
                
                
          {/*{AppRoutes.map((route, index) => {*/}
          {/*  const { element, requireAuth, ...rest } = route;*/}
          {/*  return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;*/}
          {/*})}*/}
            
            </Routes>
        </CardContext.Provider>
    );
}

export default App
