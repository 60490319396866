import React, { useContext } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { CardContext } from '../CardContext';
import { useNavigate } from 'react-router-dom';

export default function NavMenu() {

    const navigate = useNavigate();

    const handleOnSelect = (item) => {
        navigate(`/MarketChart/${item.konamiId}`);
    }

    const { allCardData } = useContext(CardContext);

    return (
        <header>
            <Navbar className="navbar-expand-sm fixed-top navbar-toggleable-sm bg-success border-bottom box-shadow" container dark>
                <NavbarBrand className="text-light" tag={Link} to="/">Card Economy</NavbarBrand>
                <ul className="navbar-nav flex-grow"  >
                    <div style={{ width: 400, zIndex: 999999 }}>
                        <ReactSearchAutocomplete
                            items={allCardData}
                            onSelect={handleOnSelect}
                        />
                    </div>
                </ul>
        </Navbar>
      </header>
    );
}
