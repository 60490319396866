
export default function Footer() {
    return (
        <footer class="text-center text-lg-start bg-success text-muted" style={{ bottom: "0", width: "100%", height: "4em", marginTop: "2em", boxShadow:"0 50vh 0 50vh #198754"}} >
            <span>Test</span>
            {/*<Navbar className="navbar-expand-sm navbar-toggleable-sm bg-success border-bottom box-shadow mb-3" container dark>*/}
            {/*    <NavbarBrand className="text-light" tag={Link} to="/">Card Economy</NavbarBrand>*/}
            {/*    <ul className="navbar-nav flex-grow">*/}
            {/*        <div style={{ width: 400, zIndex: 999999 }}>*/}
            {/*            <ReactSearchAutocomplete*/}
            {/*                items={allCardData}*/}
            {/*                onSelect={handleOnSelect}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </ul>*/}
            {/*</Navbar>*/}
        </footer>
    );
}