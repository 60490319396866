import React, { useEffect, useContext} from 'react';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { CardContext } from '../CardContext.js';
import { useNavigate } from 'react-router-dom';


export default function Landing() {

    const { allCardData } = useContext(CardContext);
    const navigate = useNavigate();


    useEffect(() => {
        document.body.parentElement.style = "background: linear-gradient(180deg, rgba(0, 27, 27, 1) 0%, rgba(25, 135, 84, 1) 50%, rgba(0, 232, 98, 1) 100%) no-repeat; height: 100%; background-size: cover;";
        document.body.style = "background: none; height: 100%;";
    }, []);

    const handleOnSelect = (item) => {
        navigate(`/MarketChart/${item.konamiId}`);
    }

    return (
        <div class="container" style={{ height: "100%", display: "flex", flexWrap: "wrap", flexDirection: "row", alignItems: "center", justifyContent: "center", alignContent: "center" }} >
            <div class="row" id="Landing Text" style={{ width: "100%", textAlign: "center", color: "white" }}>
                <h1 style={{fontSize:"80px"}} >
                    Card Economy
                </h1>
            </div>
            <div class="row" id="Landing Text" style={{ width: "100%", textAlign: "center", color: "white", paddingBottom: "30px" }}>
                <h3 style={{ fontWeight: "300" }} >
                    A TCG price analysis tool
                </h3>
            </div>
            <div class="row" id="search" style={{ width: "90%"}} >
                <ReactSearchAutocomplete
                    items={allCardData}
                    onSelect={handleOnSelect}
                    placeholder="Find a card"
                >
                </ReactSearchAutocomplete>
            </div>
            
        </div>
    )

}
